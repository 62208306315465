html, body { height: 100%; }
@font-face {
  font-family: 'FontAwesome';
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf);
  font-weight: normal;
  font-style: normal; 
}
@font-face {
  font-family: 'Roboto';
  src: '/src/fonts/Roboto-Regular.ttf';
  font-weight: normal;
  font-style: normal; 
}
.separator2a {
  text-align: center;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.separator2 {
  text-align: right;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
}
hr { 
  border: 0; 
  height: 0; 
  border-top: 1px solid rgba(0, 0, 0, 0.1); 
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.ai-right-panel {
  border: 2px solid #ddd; 
  background-color:white ;
  width: calc(60% - 4px);
  float:left;
  min-height:500px;
  position: relative;
}

.ai-sticky {
   
    z-index:10000;
    background-color: white;
  /*  -webkit-tap-highlight-color: red;*/
}
 
.ai-header-medium {
  width:100%;
  height:100px;
  background:#6cf;
}
.ai-header-big {
  width:100%;
  height:100px;
  background:#6cf;
}
 
.ai-footer-medium {
  width:100%;
  height:100px;   /* Height of the footer */
  background:#6cf;
  clear:both;
  z-index:20000;
}
.ai-footer-big {
  width:100%;
  height:100px;   /* Height of the footer */
  background:#6cf;
  clear:both;
  z-index: 20000;
}
.ui-widget-header {
  border: none !important;
}

  
  
  
.fa, .fa:before {
  display: inline-block;
  font: normal normal normal 12px/1 FontAwesome !important;
  font-size: 11px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-small, .fa-small:before {
  display: inline-block;
  font: normal normal normal 10px/1 FontAwesome !important;
  /*font-size: 11px;*/
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ai-show {
  display:block;    
}
.ai-hide {
  display:none;
}
.ai-all-options {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.ai-all-options-small {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.ai-image-big-wrap {
  display: inline-block;
  clear:both;
}
.dia-valid-false {
  color: red !important;
}
.ai-option-button {  
  position: relative;
  background-color: white;
  cursor: pointer;
}
.ai-toolbar-price{
  transform: translateY(20%);
  padding-right: 12px;
  font-weight: bold;
  color: #363636;
  float:left;
}
.ai-button-text-top {
  text-align: center;
  font-weight: bold;
  font-size: .70rem;
  word-wrap: normal;
  height: 2px;
  padding-top: 0px;
  font: normal normal bold 10px/1 Roboto !important;
}
.ai-button-text-top-image {
  padding-top: 8px !important;
}
.ai-button-text-top-shankgems {
  padding-top: 0px !important;
  height: 0px  !important;
}
.ai-button-text-top-line1 {
  padding-top: 8px !important;
}
.ai-button-text-top-ctw {
  padding-top: 0px !important;
}
.ai-button-text-line1 {
  padding-top: 8px !important;
}
.ai-button-text-top-font
{
  padding-top: 12px !important;
}
.ai-button-text-top-len
{
  padding-top: 4px !important;
}
.ai-button-text-top-dq
{
  padding-top: 12px !important;
}
.ai-button-image-line1 {
  width: 58px !important;
  height: 58px !important;
}
.ai-button-image-dq {
  width: 70px !important;
  height: 70px !important;
}
.ai-button-image-ud {
  width: 70px !important;
  height: 70px !important;
}

.ai-button-image-len {
  width: 68px !important;
  height: 68px !important;
}
.ai-button-image-ctw {
  width: 74px !important;
  height: 74px !important;
}
.ai-button-image-line2 {
  width: 86px !important;
  height:60px !important;
}
.ai-button-text-top-ds {
  height:8px !important;
}
.ai-button-image-ds {
  width: 64px !important;
  height: 64px !important;
}
.ai-button-text-top-dicut {
  height:14px !important;
}
.ai-button-image-dicut {
  width: 64px !important;
  height: 64px !important;
}

.ai-button-text-top-dicolor {
  height:30px !important;
}
.ai-button-image-dicolor {
  width: 30px !important;
  height: 30px !important;
}
.ai-button-text-top-dicolor {
  height:30px !important;
}
.ai-button-image-dicombine {
  width: 64px !important;
  height: 64px !important;
}
.ai-button-text-top-dicombine {
  height:13px !important;
}
.ai-button-image-diclarity {
  width: 64px !important;
  height: 64px !important;
}
.ai-button-text-top-diclarity {
  height:14px !important;
}
.ai-button-image-diunique {
  width: 80px !important;
  height: 64px !important;
  border-radius: 10px;
}
.ai-button-text-top-diunique {
  height:6px !important;
}



.ai-button-text-top-ce {
  height:2px !important;
}
.ai-button-text-top-cec {
  height:8px !important;
}
.ai-button-text-top-singlestone {
  height:8px !important;
}
.ai-option-best {
  width: 86px !important;
  height:120px !important;
}
.ai-image-best {
  width: 80px !important;
  height:80px !important;
  border: 1.5px solid rgba(181, 181, 181, 1) !important;
  border-radius: 10px;
}
.ai-image-best2 {
  width: 120px !important;
  height:60px !important;
  border: 1.5px solid rgba(181, 181, 181, 1) !important;
  border-radius: 10px;
}
.ai-button-image-heads {
  width: 86px !important;
  height:120px !important;
}
.ai-button-image-rs
{
  width: 58px !important;
  height:58px !important;
  padding-top: 5px !important;
}
.ai-button-image-singlestone
{
  width: 50px !important;
  height:50px !important;
  padding-top: 6px !important;
}

.ai-option-box-height-heads{
height:156px !important;
}
.ai-option-box-height-diunique{
  height:136px !important;
  }
.ai-option-border-box-height-diunique{
    height:130px !important;
  }
  .ai-option-border-box-height-heads{
  height:150px !important;
}
.ai-button-image-shank {
    width: 90px !important;
    height:90px !important;
}
.ai-button-image-shankgems {
  width: 73px !important;
  height:73px !important;
}
.ai-button-image-band {
  width: 73px !important;
  height:73px !important;
  padding-top: 1px !important;
}
.ai-option-box-height-shank{
  height:130px !important;  
}
.ai-button-text-prefix-shank {
  height: 8px !important;
}
.ai-button-text-prefix-diunique {
  padding-top: 8px !important;
  height: 6px !important;
}
.ai-button-text-diunique {
  padding-top: 9px !important;
  height: 13px !important;
}
.ai-button-text-prefix-band {
  height: 8px !important;
}
.ai-button-text-prefix-singlestone {
  height: 16px !important;
}
.ai-button-text-prefix-ctw {
  height: 7px !important;
}
  
.ai-option-border-box-height-shank{
    height:118px !important;
   /* padding-top: 1px !important;*/
}
.ai-titlediv-band{
  background-color: #4f90c5;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  margin-top: 26px;
  text-align: center;
}
.ai-titleline-band{
  color: #f6f7f9 !important;
}
  
.ai-button-text-top-shank {
  padding-top: 3px !important;
  height: 0px !important;
}
.ai-button-text-shank {
  padding-top: 3px !important;
  height: 10px !important;
}


.ai-button-text-top-pkb {
  padding-top: 2px !important;
  height: 0px !important;
}
.ai-button-text-top-set {
  padding-top: 0px !important;
  height:0px !important;
}
.ai-button-text-top-carv {
  padding-top: 0px !important;
  height:0px !important;
}
.ai-button-text-pkb {
  padding-top: 0px !important;
  height: 10px !important;
}
.ai-button-text-prefix-pkb {
  height: 0px !important;
}
.ai-button-image-pkb {
  width: 90px !important;
  height: 54px !important;
  padding-top: 10px;
  padding-bottom: 16px;
}
.ai-button-image-set {
  width: 72px !important;
  height:72px !important;
}
.ai-button-image-carv {
  width: 72px !important;
  height:72px !important;
}

.ai-button-image {
 width:60px;
 height:60px;
 margin-left: auto;
 margin-right: auto;
 display: block;
 pointer-events: none;
 user-select: none;
}
.ai-button-text-prefix {
  text-align: center;
  font-size: .70rem;
  word-wrap: normal;
  height: 11px;
  padding-top:0px;
  font: normal normal normal 10px/1 Roboto !important;
}
.ai-button-text-prefix-line1 {
  height: 9px !important;
}
.ai-button-text-heads {
  padding-top: 2px;
}
.ai-button-text {
  text-align: center;
  font-weight: bold;
  font-size: .70rem;
  word-wrap: normal;
  height: 15px;
  padding-top:0px;
  font: normal normal normal 10px/1 Roboto !important;
}
.ai-text-select {
    color:#4f90c5;   

}
.ai-text-unselect {
  color: gray;
}
/* .ai-border-box {
  border: 1.5px solid #ddd;
  border-radius: 10px;
  margin:3px;
} */
.ai-option-button-120px .ai-border-box{
  height: 110px;
}
.ai-option-button-100px .ai-border-box{
  height: 92px;
}
.ai-name-stone .ai-option-button-100px .ai-border-box img {
  width:30px;
  height:30px;
  margin-top:6px;
  margin-bottom:6px;
}

.ai-name-stone .ai-option-button-120px .ai-border-box img {
  margin-top:1px;
  margin-bottom:1px;
}

.ai-name-metal .ai-option-button-100px .ai-button-text-top, .ai-name-stone-count .ai-option-button-100px .ai-button-text-top,
 .ai-name-size .ai-option-button-100px .ai-button-text-top {
  height: 0px;
}
.ai-name-stone-count .ai-option-button-100px img, .ai-name-size .ai-option-button-100px img {
  margin-top: 11px;
} 
.ai-name-metal .ai-option-button-100px img {
  margin-top: 4px;
} 

.ai-name-stone-count .ai-option-button-120px img, .ai-name-size .ai-option-button-120px img {
  margin-top: 5px;
} 

.ai-name-metal .ai-option-button-120px img {
  margin-top: -2px;
  margin-bottom: 4px;
}

.corner.ai-select {
  position: absolute;
  top: 3px;
  right: 3px;
  border: 2px solid transparent;
  border-width: 1.2rem; /* 1.2em; */
  border-right-color: #4F90C5;
  border-top-color: #4F90C5;    
  z-index: 5;
  border-top-right-radius: 8px;
}
.ai-check-icon {
  top: 8px;
  right: 9px;
  position: absolute;
  z-index: 10;  
  color: white;
  
}
.ai-check-icon.ai-select:before {
  content: "\F00C";
}




.ai-next-arrow, .ai-prev-arrow {
 display: block !important;
}

.ai-next-arrow:before, .ai-prev-arrow:before {
 color: #4F90C5 !important;
 font-size: 30px !important;
}
.ai-next-arrow:before {
  content: "\F054" !important
}
.ai-prev-arrow:before {
  content: "\F053" !important
}

.ai-sticky .slick-next::before {
  
  content: "\F054" !important;

  display: inline-block;
  font: normal normal normal 12px/1 FontAwesome !important;
  font-size: 11px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #4F90C5 !important;
  font-size: 30px !important;
}

.ai-sticky .slick-prev::before {
  
  content: "\F053" !important;

  display: inline-block;
  font: normal normal normal 12px/1 FontAwesome !important;
  font-size: 11px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #4F90C5 !important;
  font-size: 30px !important;
}

.ai-option-title {
  font: normal normal normal 14px/1 Roboto !important;
}

.ai-img-small {
  margin-left: auto;
  margin-right: auto;
}
.ai-img-big {
  margin-left: auto;
  margin-right: auto;
}
.ai-img-medium {
  margin-left: auto;
  margin-right: auto;
}

.ai-option-wrap {
  margin-left: auto;
  margin-right: auto;
}

.ai-header-include1 {
  height: 74px;
  }

.ai-body, .ai-footer, .ai-header {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  position: relative;
  -webkit-text-size-adjust:none;
  -ms-text-size-adjust:none;
  -moz-text-size-adjust:none;
  text-size-adjust:none;
  font-size-adjust: none;
  background-color: white;
  -webkit-tap-highlight-color: transparent;
}
.ai-panels:after {
  content: "";
  display: table;
  clear: both;
}

.ai-inputtext-wrap {
  float:left
} 
.ai-inputtext-count {
    color: #aaa;
    font-size: 10px;
    clear: both;
    padding-left: 2px;
    padding-top: 2px;
 
}
.ui-float-label label {
  font-size: 18px;
}

.ai-inputtext-box {
  padding-top:5px;
  margin-left:0px;
  margin-right:30px;
  clear:both;
  position:relative;
}
.ai-inputtext-box-dia {
  padding-top:0px;
  margin-left:10px;
  margin-right:30px;
  clear:both;
  position:relative;
}
.ai-dia {
  margin-top:20px;
  margin-bottom:2px;
  margin-left:2px;
  color: #4f90c5;
  
}

.ai-inputtext-box input, .ai-inputtext-box span {
  width: 100%;
}
.ai-inputtext-box input {
  height: 40px;
}
.slick-dots-big li {
  width:90px !important;
  height:90px !important;
  margin:0px 2px !important;
  
}
.slick-dots-big {
  bottom: -102px !important;
}
.slick-dots-medium li {
  width:45px !important;
  height:45px !important;
  margin:0px 2px !important;
  
}
.slick-dots-small li {
  width:25px !important;
  height:25px !important;
  margin:0px 2px !important;
  
}
.slick-dots-small {
  bottom: -30px !important;
}
.slick-dots-medium {
  bottom: -50px !important;
}
.ai-src-img-medium {
  width:250px;
  height:250px
}
.ai-src-img-small {
  width:100px;
  height:100px
}
.ai-src-img-big {
  width:500px;
  height:500px
}
.ai-img-wrap-big{
  width:500px;
  height:500px;
}
.ai-img-wrap-medium{
  width:250px;
  height:250px;
}
.ai-img-wrap-small{
  width:100px;
  height:100px;
}
.ai-img-thumbnail-big{
  width:90px;
  height:90px;
}
.ai-left-panel {
  height:500px ;
  width: 40% ;
  float:left ;
  position: relative;
}

.ai-img-thumbnail-medium{
  width:45px;
  height:45px;
}
.ai-img-thumbnail-small{
  width:25px;
  height:25px;
}



.ai-toolbar-group{
  width:100%;
  z-index: 15000;

}
.ai-toolbar-panel{
  width:100%;
}
.ai-empty-prefix{
  position: relative;
  transform: translateY(-50%);
}
.ai-sticky-left, .ai-sticky{
  text-align: center;
}
.ai-best-dialog {
/*  width: 90% !important;*/
  z-index: 30000 !important;
}
.ai-bottom {
  height:800px;
  background-color: aliceblue;
}
.ai-text-unselect, .ai-text-select, .ai-option-title, .ai-option-title-left, .ai-option-title-optional {
  user-select: none;
}
.ai-option-title, .ai-option-title-left, .ai-option-title-optional {
  display: inline-block;
}
.ai-modalbutton {
  display: block !important;
  padding-bottom:20px;
  margin: 0 auto 15px auto !important;
}
.ai-modalbutton2 {
    display: block !important;
    margin: 0 auto !important;
    
}

.ai-option-title-optional {
  margin-left: 5px;
  font-family: Roboto;
  transform: translateY(-7.5%);
}
.ai-option-title {
  color: #4f90c5;
  margin-left: 4px;
  font-weight: bold !important;
}
.ai-option-title-left {
  font-size: 18px;
  font-family: Roboto;
  font-weight: bold;
  color: #808080;
}
.ai-input-box {
  margin-bottom:10px;
  margin-left:30px;
}
.ai-input-box-dia {
  margin-bottom:10px;
  margin-left:30px;
}
.ai-engr-show {
    border: 1px solid #ddd;
    padding: 1px;
    margin-bottom: 10px;
}
.ai-input-box-bottom {
  /*margin-top:20px;*/
  margin-bottom:30px;
  margin-left:0px;
  /*margin-right:30px;*/
  clear:both;
  font-size: 12px;
  font-family: Roboto;
  
  color: #808080;
}
.ai-review {
  width:"250px" ;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.ai-review-title {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size:18px;
}
.ai-review-header {
  color:white !important;
  background-color: #4f90c5 !important;
  font-weight: bold !important;
  font-size: 14px;
  text-align: center;
}
.ai-table-column1 {
  width: 55%;
  text-align: left;
  padding-bottom: 2px;
  padding-top: 2px;
  font-size:18px;
  float:left;
}
.ai-table-column2 {
  width: 45%;
  text-align: right;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size:18px;
  float:left;
}

.ai-table-group {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  
}
.ai-table-row {
  width: 100%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size:18px;
}
.ai-table-background1 {
  background: #f9f9f9;
}
.ai-table-background2 {
  background:white;
}


.ai-zoom {
  position: absolute;
  width: 34px;
  top: 20px;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
  z-index:11000;
}
.ai-zoom-small {
  position: absolute;
  width: 34px;
  top: 5px;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
  z-index:11000;
  
}
.ai-startover {
  position: absolute;
  width: 34px;
  top: 20px;
  left: 0;
  margin-left: 3px;
  cursor: pointer;
  z-index:11000;
  /*-webkit-tap-highlight-color: transparent;*/
}
.ai-startover-small {
  position: absolute;
  width: 34px;
  top: 5px;
  left: 0;
  margin-left: 10px;
  cursor: pointer;
  z-index:11000;
  /*-webkit-tap-highlight-color: transparent;*/
}


.ai-title {
  font-size:18px;
  font-family: Roboto;
  text-align: center;
  vertical-align: middle;
  line-height: 25px; 
  padding-left:34px;
  padding-right:34px;
  color: gray;
  /*height:27px;*/
  /*clear: both;*/
  padding-top:12px;
  margin-bottom: 5px;
  /* margin-left: 10px;
  margin-right: 10px;
   */
}
.ai-title-small {
  font-size:18px;
  font-family: Roboto;
  text-align: center;
  vertical-align: middle;
  line-height: 25px; 
  width: 98%;
  color: gray;
  /*height:27px;*/
  /*clear: both;*/
  padding-top:12px;
  margin-bottom: 5px;
}

.ai-cfg-small {
  color: gray;
  font-size:10px;
  font-family: Roboto;
  text-align: left;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ai-cfg {
  color: gray;
  font-size:10px;
  font-family: Roboto;
  text-align: left;
  margin-top: 2px;
  margin-left: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-accordion-header-text {
  color:white !important;
 
}

.slick-next {
  right: -23px;
}

.ai-textinput-hr {
  clear:both;
  height:5px;
}

.ai-panels:active {
  background-color: white;
}

.ai-version {
  float: right;
  font-size: 10px;
}
.ril__outer1 {
  z-index:21000 !important;
}
.ril__inner {
  background-color: rgba(0, 0, 0, 0.85) !important ;
  
}
.ril__toolbar {
  background-color: transparent ;
}
.ril__navButtons {
  /* top:"410px"; */
  background-color: transparent;
  margin-left: -13px;
  margin-right: -13px;
}
.ai-special-note {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 14px;
  clear: both;
}
.ReactModal__Overlay {
  z-index:21000 !important;

}
*:focus {
  outline: none;
}
.slick-next, .slick-prev {
  height:30px !important;

}

.ril__builtinButton {
  margin-top: 50px !important;
}
.ai-review-note {
  margin-top: 20px;
  font-size: 12px;
}

.ai-bcolor-kay {  
  background-color:#440c44 !important;
  background:#440c44 !important;
  border: 1px solid #515666 !important;
}
.ai-bcolor2-kay {  
  background-color:#440c44 !important;
  background:#440c44 !important;
}
 .ai-fcolor-kay:before {  
  color:#440c44 !important;
}
.ai-color-kay {  
  color:#440c44 !important;
}
.ai-review-kay {
  background-color:#440c44 !important;
}
.ai-select-kay {
  border-right-color: #440c44 !important;
  border-top-color: #440c44 !important;
}   


.ai-bcolor-jared { 
  background-color: #0e5b55 !important;
  background: #0e5b55 !important;
  border: 1px solid  #0e5b55 !important;
}
.ai-bcolor2-jared { 
  background-color: #0e5b55 !important;
  background: #0e5b55 !important;
  
}
.ai-fcolor-jared:before {  
  color: #0e5b55 !important;
}
.ai-color-jared {  
  color: #0e5b55 !important;
}
.ai-review-jared {
  background-color: #0e5b55 !important;
}
.ai-select-jared {
 border-right-color:  #0e5b55 !important;
 border-top-color:  #0e5b55 !important;
}    



.slick-current img{
  z-index: 5;
  position: relative;
}


/* .ai-button-text.ai-empty-prefix.ai-button-text-heads {
  padding: 10% !important;
} */

.ai-button-image-ce {
  width: 71px;
  height: 71px;
}

.ai-button-text-prefix-ce {
  height: 9px;
}

.ai-text-unselect > .ai-border-box {
  border: 1.5px solid rgba(221, 221, 221, 0);
  border-radius: 10px;
  margin:3px;
}
.ai-border-box:hover {
  border: 1.5px solid rgba(181, 181, 181, 1) !important;
  border-radius: 10px;
  margin:3px;
}
.ai-text-select > .ai-border-box {
  border: 1.5px solid #ddd;
  border-radius: 10px;
  margin:3px;
}
.buttonUnique {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: #440c44;
  border:0px;
  padding:4px;
  position: relative;
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
}


.button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: rgb(255,255,255);
  border:0px;
  padding:0px;
  position: relative;

}
.button2 {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: rgb(255,255,255);
  border:0px;
  padding:0px;

}

.button2.focus-ring {
  outline:2px solid dodgerblue;
  outline-offset: 0px; 
  border:0px; 
}
.button3 {
  -webkit-appearance: none;
  appearance: none;
  background: #2399e5;
  color: rgb(255,255,255);
  border:0px;
  padding:3px;

}

.button3.focus-ring {
  outline:2px solid dodgerblue;
  outline-offset: 0px; 
  border:0px; 
}

.button.focus-ring .ai-border-box {
  outline:none;
  outline-offset: 0px; 
  border: 2px solid dodgerblue;
  border-radius: 10px;
    margin: 2px;
}

.ai-option-box.ai-name-ud > button.ai-modalbutton, .ai-option-box.ai-name-ud > button.ai-modalbutton2 {
  margin-top: -10px !important;
  height: 29px !important;
}

.ai-option-box.ai-name-ce > button.ai-modalbutton, .ai-option-box.ai-name-ce > button.ai-modalbutton2 {
  margin-top: -10px !important;
  height: 29px !important;
}

.ai-panels .ai-option-box.ai-name-ce > button.ai-modalbutton, .ai-panels .ai-option-box.ai-name-ce > button.ai-modalbutton2 {
  margin-top: -16px !important;
}

.ai-option-box.ai-name-ud > button.ai-modalbutton.ai-bcolor-zales, .ai-option-box.ai-name-ud > button.ai-modalbutton2.ai-bcolor-zales,
.ai-option-box.ai-name-ce > button.ai-modalbutton.ai-bcolor-zales, .ai-option-box.ai-name-ce > button.ai-modalbutton2.ai-bcolor-zales {
  margin-top: 24px !important;
}

.ai-panels .ai-option-box.ai-name-ud > button.ai-modalbutton.ai-bcolor-zales, .ai-panels .ai-option-box.ai-name-ud > button.ai-modalbutton2.ai-bcolor-zales,
.ai-panels .ai-option-box.ai-name-ce > button.ai-modalbutton.ai-bcolor-zales, .ai-panels .ai-option-box.ai-name-ce > button.ai-modalbutton2.ai-bcolor-zales {
  margin-top: 10px !important;
}

.ai-option-box.ai-name-ud > button.ai-modalbutton > span, .ai-option-box.ai-name-ud > button.ai-modalbutton2 > span,
.ai-option-box.ai-name-ce > button.ai-modalbutton > span, .ai-option-box.ai-name-ce > button.ai-modalbutton2 > span {
  padding: .25em 0.5em !important;
}

div.ui-toolbar-group-right > button.button3 {
  border-radius: 3px !important;
}

.ai-best-img {
  cursor: pointer !important;
}

.ai-bcolor3-kay .ai-best-close button {
  background-color:#440c44 !important;
  background:#440c44 !important;
  border: 1px solid #515666 !important;
}

.ai-bcolor3-jared .ai-best-close button {
  background-color: #0e5b55 !important;
  background: #0e5b55 !important;
  border: 1px solid  #0e5b55 !important;
}

.ui-dialog-content.ui-widget-content {
  width: 100%;
  height: calc(100% - 40px);
  text-align: center;
  scrollbar-width: none;
}

.ai-panels .ui-dialog-content.ui-widget-content {
  scrollbar-width: unset;
}

.ai-best-dialog {
  overflow: hidden;
}

.ai-best-element {
  display: inline-block;
  overflow: hidden;
  max-height: 300px;
  min-height: 300px;
  position: relative;
  width: 100%;
}

.ai-best-img {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  max-width: 300px;
  max-height: 300px;
}

.ui-dialog-title {
  user-select: none;
}

.ai-panels .ai-best-element, .override .ai-best-element {
  display: inline-block;
  overflow: visible;
  position: relative;
  width: unset !important;
}

.ai-panels .ai-best-img, .override .ai-best-img {
  position: static;
  transform: none;
}

.ai-panels .ai-best-img {
  margin-top: 0;
}

.ai-best-close button {
  margin: 0 !important;
}

.ai-best-dialog .ai-best-close {
  text-align: center !important;
}

.ai-panels .ai-best-dialog .ai-best-close {
  text-align: right !important;
}

.ui-dialog-titlebar {
  text-align: center !important;
  padding: .5em .75em !important;
}

.ai-panels .ui-dialog-titlebar {
  text-align: left !important;
}

.ai-cfg > div, .ai-cfg-small > div {
  display: inline;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

.bs-adjust {
  transform: translateX(-300px);
}



.ai-src-img-big, .ai-src-img-medium, .ai-src-img-small {
  cursor: pointer;
}

.ai-image-big-wrap.ai-init {
  pointer-events: none;
}

.ai-sticky.ai-init .ai-startover-small, .ai-sticky.ai-init .ai-zoom-small, .ai-body.ai-init .ai-cfg-small, .ai-body.ai-init .ai-title-small,
.ai-sticky-left.ai-init .ai-startover, .ai-sticky-left.ai-init .ai-zoom, .ai-sticky-left.ai-init .ai-cfg, .ai-left-panel.ai-init .ai-title {
  visibility: hidden;
}



.ai-sticky.ai-init .slick-list, .ai-sticky-left.ai-init .slick-list {
  overflow: visible !important;
}

.ai-image-big-wrap.ai-init .ai-src-img-medium {
  transform-origin: bottom;
  transform: scale(1.2);
}

.ai-image-big-wrap.ai-init .ai-src-img-small {
  transform-origin: bottom;
  transform: scale(1.5);
}



.ril__builtinButton {
  margin-top: 8px !important;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.ril__toolbarRightSide {
  padding-right: 12px;
}

.lblandscape .ril__navButtons {
  margin-left: max(calc((100% - min(100vw,100vh))/2 - 60px), 0px) !important;
  margin-right: max(calc((100% - min(100vw,100vh))/2 - 60px), 0px) !important;
}

.lbportrait .ril__navButtons {
  margin-left: max(calc((100% - min(100vw,100vh))/2), 0px) !important;
  margin-right: max(calc((100% - min(100vw,100vh))/2), 0px) !important;
  filter: invert(1);
}

.lbsquare .ril__toolbarRightSide {
  padding-right: 10px !important;
  filter: invert(1); 
}

.lbsquare .ril__toolbar {
  padding-top: calc((100vh - 100vw)/2) !important;
  margin-right: max(calc((100% - min(100vw,100vh))/2), 0px) !important;
}
.ai-chat-block {  
   margin-left:30px;
   margin-right:30px;
   background:#fafafa;
   
   /*width:80%;*/
   display:flex;
 }
 
 .ai-chat-block2 {  
    margin-left:40px;
    margin-right:40px;
    background:#fafafa;
    /*width:80%;*/
    display:flex;
  }
 .ai-chat-icon {  
   padding-top: 17px;
   width:80px;
   padding-left:10px;
 }
 
 .ai-chat-text {  
   width:80%;
   color:#515666;
   padding-right:5px;
 }
 
 .ai-chat-text, a {  
   width:100%;
   color:#515666;
 }
 .ai-esa-block {  
  background:#fafafa;
  display:flex;
}

.ai-esa-img {  
padding:10px;
}
.ai-esa-font {  
  font-size:small;
  }
  
.ai-esa-icon {  
  display:flex;
  color:#515666;
  align-items: center;
}
.ai-esa-text {  
  display:flex;
  font-size:13px;  
}
.ai-esa-th {  
  padding:8px;
  text-align:left;
  font-size:13px;
  
}
.ai-esa-table {  
  width:100%;
  
}

.ai-esa-thead {  
  background: black;
  color: white;
}
.ai-esa-thead-kay {  
  background: #440c44;
  color: white;
}
.ai-esa-thead-jared {  
  background: #0e5b55;
  color: white;
}
.ai-esa-thead-kayoutlet {  
  background: #B56F7E;
  color: white;
}
.ai-esa-thead-zales {  
  background: #4f90c5;
  color: white;
}
.ai-esa-thead-zalesoutlet {  
  background: #4f90c5;
  color: white;
}
.ai-esa-discl{
  font-size:xx-small;
 }
 .ai-esa-padding {
  padding-top: 5px;
 }  
