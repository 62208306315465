.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navbar .desktop {
    display: flex;
    padding-top: 15px;
    height: 55px;
}

.navbar .ui-menubar {
    width: 100%;
}

.navbar .ui-menuitem-link {
    display: flex !important;
}

.navbar .ui-widget-content {
    z-index: 11001;
}

.navbar .ui-button {
    margin: 3px;
    font-size: 20px;
}

.navbar img {
    max-height: 40px;
    margin-left: 10px;
}

.navbar .ui-menu {
    border: none;
    background: none;
}

.navbar .mobile {
    display: flex;
    width: 100%;
}

.navbar :active {
    background-color: unset;
}

.navbar {
    background-color: #6cf;
    background-image: -webkit-linear-gradient(left, #234b55, #0b222a);
    background-image: -o-linear-gradient(left, #234b55, #0b222a);
    background-image: linear-gradient(90deg, #6cf, #0b7cb4);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#234B55", endColorStr="#0B222A", gradientType="1");
    display: block;
    padding: 0;
    /*height: 70px;*/
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    margin-bottom: 10px;
}

.ui-menuitem-link:hover, .ui-menu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
    background-color: #2399e5 !important;
}

.ui-menu .ui-menu-child {
    background: white !important;
}

.navbar .mobile img {
    position: absolute;
}

section {
    display: flex;
    /*flex-wrap: wrap;*/
    flex: 200px;
    /*flex-direction: row;*/
}

.footer {
    position: inherit;
    background-color: white;
    margin-top: 20px;
    border-top: 2px solid #2399e5;
    font-size: 15px;
}

.footer-spliter {
    border-bottom: 1px solid grey;
    width: 90%;
}

.footer a {
    list-style: none;
    text-decoration: none;
    color: grey;
}

.footer-item {
    padding: 7px 0px 7px 0px;
}

.footer-icon {
    padding-right: 10px;
}

.footer .ui-accordion-header-text {
    color: #1f1f1f!important;
    text-decoration: underline;
}

.accordion-content {
    background-color: white;
    position: inherit;
}
